import React from 'react';
import { YouTubePlaylist } from "@codesweetly/react-youtube-playlist";
import './VideosYTube.css';

const VideoYTube = () => {
  // Ensure that apiKey and playlistId are defined before rendering the component
  const apiKey = 'AIzaSyCYBRTs07ykxUzNVnKrSxEN9agODWFld8o';
  const playlistId = 'KAWJi11iQ&list=PL6QX_a8H99e6VGvSaCDKqBEs0PECF4rBV';

        return (
          <div>
            <h2>YouTube Playlist</h2>
            {/* Wrap YouTubePlaylist component with conditional rendering */}
            {apiKey && playlistId && (
              <YouTubePlaylist
                
              apiKey={apiKey}
                playlistId={playlistId}
                className="youtube-playlist"
              />
            )}
          </div>
        );
      
      }
      
export default VideoYTube;
