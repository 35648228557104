import React from 'react';
import VideosYTube from '../containers/Videos/VideosYTube';
import Footer from '../containers/Footer/Footer';

const Videos = () => {
  return (
    <div>
      <VideosYTube />
      <Footer />
    </div>
  );
};
  
export default Videos;