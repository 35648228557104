import React from 'react';
import { Link } from 'react-router-dom';
import geoJson from '../../eventos.json';  // Ensure the path is correct
import './EventsDisplay.css';  // CSS file for styling

function EventsDisplay() {
  const today = new Date(); // Gets today's date

  const upcomingFeatures = geoJson.features.filter(feature => {
    const dateParts = feature.properties.date.split('/');
    const eventDate = new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
    return eventDate >= today; // Filters out past events
  });

  // Sort the filtered events by date in ascending order to get the nearest upcoming events
  const sortedFeatures = upcomingFeatures.sort((a, b) => {
    const datePartsA = a.properties.date.split('/');
    const dateA = new Date(datePartsA[2], datePartsA[0] - 1, datePartsA[1]);
    const datePartsB = b.properties.date.split('/');
    const dateB = new Date(datePartsB[2], datePartsB[0] - 1, datePartsB[1]);
    return dateA - dateB; // Sort in ascending order
  });

  // Select the first two upcoming events to display
  const recentEvents = sortedFeatures.slice(0, 2);

  // Function to open ticket URL
  const openTicketUrl = (url) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  return (
    <div className="main-container">
      <h1>Proximos eventos</h1>
      <div className="events-section">
        {recentEvents.length > 0 ? recentEvents.map(feature => (
          <div key={feature.properties.id} className="event-card">
            <img src={feature.properties.image_url || '../../imgs/ckan-img1.jpg'} alt={feature.properties.title || 'Coming Soon'} className="event-thumbnail" />
            <div className="event-details">
              <h2>{feature.properties.title || 'Coming Soon'}</h2>
              <p>Fecha: {feature.properties.date || 'To be announced'}</p>
            </div>
            <button className="tickets-btn" onClick={() => openTicketUrl(feature.properties.ticket_url)} disabled={!feature.properties.ticket_url}>
              Tickets
            </button>
          </div>
        )) : (
          <div className="event-card">
            
            <div className="event-details">
              <h2>Coming Soon</h2>
              <p>Fecha: To be announced</p>
            </div>
            <button className="tickets-btn" disabled>Tickets</button>
          </div>
        )}
      </div>
      <Link to="/eventos" className="view-more-btn">Ver más</Link>
    </div>
  );
}

export default EventsDisplay;
