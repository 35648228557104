import React from 'react';
import './Aboutme.css';
import ckanImage from '../../imgs/ckan-img2.png';

const AboutMe = () => {
  return (
    <div className="about-me-container">
      <div className="about-me-content">
        <img src={ckanImage} alt="C-Kan" className="about-me-image" />
        <h1>Acerca de C-Kan</h1>
        <p>
          Su amor por los perros le da su nombre callejero y aunque ahora se destaca como uno de los exponentes más importantes del hip-hop y rap mexicano,
          C-Kan no olvida su barrio pobre en Guadalajara, Jalisco, donde creció. Ese a donde salía armado con una pistola 9mm por las noches para defenderse,
          y después adentrarse en el estudio con un beat que expusiera la experiencia vivida entre drogas y violencia.
          Pero C-Kan — que a los 12 años se convirtió en el hombre de la casa tras la muerte de su padre — no es una víctima de su entorno, sino un vocero de la realidad.
          Una realidad que los gobiernos ignoran y esconden ante la sociedad global.
        </p>
        <p>
          Callarse no es opción para C-Kan, y así como acusa al presidente de su país, Enrique Peña Nieto,
          por la represión de los suyos en el tema Semillas, también reprocha y corrige al mandatario del primer mundo Donald Trump, el maltrato hacia los que buscan una mejor vida, en Soy ilegal.
          “Esa gente, ¿qué sabe de crecer en el barrio? A ellos les regalan lo que nosotros deseamos. No saben lo que es ver a una niñita de 17 años tener un aborto porque el padre de su hijo desapareció.
          O que exista la violencia intrafamiliar en su entorno. Todo eso me hace escribir mis temas. Me siento orgulloso de venir de esa gente, de haber vivido esas experiencias”, cuenta.
          Sus líricas de exposición social han sido coreadas por miles de fanáticos que lo han convertido en profeta en su propia tierra y catapultado al estrellato internacional.
          Pero ahora, en su nuevo álbum titulado “Días de sol”, explora el sentimiento de amor que le despertó el convertirse en padre de un varón hace siete años,
          y rinde tributo a los homies de su barrio que se han mantenido fieles a defender sus calles.
        </p>
        <p>
          “Yo regreso ahí cuando puedo, aún tengo familia que vive en el barrio. Me siento con una responsabilidad social de dar a conocer lo que existe.
          Trato de ser específico en mis mensajes utilizando la inteligencia, haciendo una canción que suene violenta, sin serlo”, asegura.
          C-Kan es actualmente el artista mexicano más destacado de su género, con más de 50 videos oficiales en VEVO, algunos de los cuales superan los más de 100 millones de vistas,
          y ha encabezado importantes festivales a nivel mundial, como Urbano Fest, Prudence Fest, Revolution Fest, Budweiser Made in America Festival y High Tmes Magazine: Cannabis Cup, entre otros.
          Además, ha colaborado con artistas de talla internacional como Baby Bash, Frankie J, South Park Mexican, Alika, Kinto Sol y Kevin Gates, entre otros.
          Pero ese no es el tope para el cantante quien propone un armisticio hacia la igualdad y cuyas composiciones han llegado a la pantalla grande en cintas como Compadres,
          y en importantes series como <a style={{ color: 'red' }} target="_blank" rel="noreferrer" href="https://youtu.be/oo5nnF2Bk4E">Ingobernable</a> para Netflix y <a style={{ color: 'red' }} target="_blank" rel="noreferrer" href="https://youtu.be/fHyd_snDxDA">13 Esposas para Blim</a>.
          Su música no sólo le habla a quien entiende su mismo idioma, sino que ha cruzado fronteras y aún aquel que no sabe su historia, se relaciona con su tema Un par de balas,
          el cual se destacó en serie italiana Soto Copertura para la cadena Rai <a style={{ color: 'red' }} target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/0B4zq9CmkXeZON0ZRY1B5dGJNWUU/view">(Ver Aqui)</a>.
          Tal como lo presume una de sus canciones, la calle sabe de su nombre, y este fiel representante de México penetra en la nueva era digital,
          logrando colocar sus temas en la adicción de la nueva generación: los videojuegos.
        </p>
        <p>
          Los temas M.E.X.I.C.O y La Calle Sabe de mi Nombre, se incluyen en la serie de juegos <a style={{ color: 'red' }} target="_blank" rel="noreferrer" href="https://youtu.be/ksalCVLJYT8">Tom Clancy’s Ghost Recon Wildlands</a> y <a style={{ color: 'red' }} target="_blank" rel="noreferrer" href="https://youtu.be/hLz02sgSzjc">Watch Dogs 2 </a>.
          “Con el apoyo de la gente, te das cuenta que puedes utilizar tu vida para crear algo que prospere por muchísimo tiempo”, enfatiza.
        </p>
      </div>
    </div>
  );
}

export default AboutMe;
