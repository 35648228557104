import React, { useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import "./Map.css";
import geoJson from "./eventos.json";
import busIcon from "./bus-icon.png"; // Ensure you have a bus icon image in your project directory

mapboxgl.accessToken = "pk.eyJ1IjoiZWVzcGRldiIsImEiOiJjbHZuaHJ4dW0wZHNmMmlubnpscXNzOGc4In0.z0wGQ-JbOcgaMEucU-NEHA";

const Map = () => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const busMarkerRef = useRef(null); // Ref for the bus marker
  const lineLayerRef = useRef(null); // Ref for the dashed line layer
  const intervalIdRef = useRef(null); // Ref for the intervalId variable

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [-102.2785739, 38.4585199],
      zoom: 3,
      attributionControl: false,
      logoPosition: 'bottom-left',
      preserveDrawingBuffer: true,
      dragRotate: false,
    });

    mapRef.current = map; // Save map instance to useRef for access in other functions

    map.on('load', () => {
      // Create markers and pop-ups for all events
      geoJson.features.forEach(feature => {
        const { geometry, properties } = feature;

        // Determine if the event has already passed
        const eventDate = new Date(properties.date);
        const currentDate = new Date();
        eventDate.setHours(20, 0, 0, 0); // Set event end time to 9 PM

        const markerColor = currentDate <= eventDate ? '#27bae2' : '#A9A9A9'; // #27bae2 for future event, gray for past event

        // Create a marker element with color #27bae2 for future events
        const marker = new mapboxgl.Marker({ color: markerColor })
          .setLngLat(geometry.coordinates)
          .addTo(map);

        // Attach popup only if event is in the future (not gray)
        if (markerColor === '#27bae2') {
          // Construct popup content with an image if available
          const popupContent = `
            <div class="popup-content">
              <div class="popup-image-container">
                ${properties.image_url ? `<img src="${properties.image_url}" alt="${properties.title}" />` : ''}
              </div>
              <div class="popup-info">
                <h3 class="popup-title">${properties.title}</h3>
                <p class="popup-date"><strong>Date:</strong> ${properties.date}</p>
                <button onclick="window.open('${properties.ticket_url}', '_blank');" class="popup-button">Boletos</button>
              </div>
            </div>
          `;

          // Create a popup with unique ID and set its content
          const popup = new mapboxgl.Popup({ offset: 25 })
            .setHTML(popupContent);

          // Attach the popup to the marker
          marker.setPopup(popup);

          // Event handler for marker click
          marker.getElement().addEventListener('click', () => {
            const now = new Date();

            // Disable click if current time is past 9 PM on the event day
            if (now <= eventDate) {
              map.flyTo({ center: geometry.coordinates, zoom: 16 });  // Fly to the point and increase zoom
            }
          });
        }
      });

      // Add a tour bus marker
      const busMarker = new mapboxgl.Marker({
        element: document.createElement('div')
      }).setLngLat(geoJson.features[0]?.geometry.coordinates || [-102.2785739, 38.4585199])
        .addTo(map);

      const busMarkerElement = busMarker.getElement();
      busMarkerElement.className = 'bus-marker';
      busMarkerElement.style.backgroundImage = `url(${busIcon})`;

      // Store bus marker reference
      busMarkerRef.current = busMarker;

      // Function to update the bus position based on current date and time
      const updateBusPosition = (coordinates, dates) => {
        const now = new Date();
        const lastEventDate = new Date(dates[dates.length - 1]);
      
        if (now > lastEventDate) {
          // If the current time is past the last event, the bus should stay at the last location.
          busMarkerRef.current.setLngLat(coordinates[coordinates.length - 1]);
          updateDashedLine(coordinates[coordinates.length - 1], coordinates[coordinates.length - 1]);
          return;
        }
      
        for (let i = 0; i < dates.length - 1; i++) {
          const currentEventDate = new Date(dates[i]);
          const nextEventDate = new Date(dates[i + 1]);
          const elevenPm = new Date(currentEventDate);
          elevenPm.setHours(23, 0, 0, 0);
      
          if (now >= currentEventDate && now <= elevenPm) {
            busMarkerRef.current.setLngLat(coordinates[i]);
            updateDashedLine(coordinates[i], coordinates[i]);
            break;
          } else if (now > elevenPm && now <= nextEventDate) {
            const duration = nextEventDate - elevenPm;
            const elapsed = now - elevenPm;
            const t = elapsed / duration;
            const start = coordinates[i];
            const end = coordinates[i + 1];
            const lng = start[0] * (1 - t) + end[0] * t;
            const lat = start[1] * (1 - t) + end[1] * t;
            busMarkerRef.current.setLngLat([lng, lat]);
            updateDashedLine(start, end);
            break;
          }
        }
      };

      const eventCoordinates = geoJson.features.map(event => event.geometry.coordinates);
      const eventDates = geoJson.features.map(event => event.properties.date);

      updateBusPosition(eventCoordinates, eventDates);
      intervalIdRef.current = setInterval(() => updateBusPosition(eventCoordinates, eventDates), 60000); // Update every minute
    });

    // Clean up on unmount
    return () => {
      map.remove();
      clearInterval(intervalIdRef.current);
    };
  }, []);

  // Function to update or create the dashed line path between two points
  const updateDashedLine = (start, end) => {
    const map = mapRef.current;

    // Remove existing line layer if it exists
    if (map.getLayer('dashed-line')) {
      map.removeLayer('dashed-line');
      map.removeSource('dashed-line');
    }

    // Create a GeoJSON object with the line coordinates
    const lineCoordinates = [start, end];
    const geojson = {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: lineCoordinates,
      },
    };

    // Add the line source and layer to the map
    map.addSource('dashed-line', {
      type: 'geojson',
      data: geojson,
    });

    map.addLayer({
      id: 'dashed-line',
      type: 'line',
      source: 'dashed-line',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#FF4500', // Adjust line color as needed
        'line-width': 2,
        'line-dasharray': [2, 2], // Adjust the dash pattern [dash, gap]
      },
    });

    // Store the line layer reference for removal later
    lineLayerRef.current = true;
  };

  return <div className="map-container" ref={mapContainerRef} />;
};

export default Map;