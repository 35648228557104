import React, { useEffect } from 'react';
import Footer from '../containers/Footer/Footer';
import AboutMe from '../containers/AboutMe/Aboutme';

const About = () => {
  useEffect(() => {
    // Scrolls the window to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); // The empty array ensures this effect runs only once after the initial render

  return (
    <div>
      <AboutMe />
      <Footer />
    </div>
  );
};

export default About;
