import React from 'react';
import './MusicPreview.css';

function MusicPreview() {
    return (
        <div className="music-section">
            <div className="music-content">
                <h1 id="musictitle">Musica</h1>
                <div className="album-cover">
                    <img src="https://lastfm.freetls.fastly.net/i/u/ar0/eb73f7858c3331fb2da6c25615d67409.jpg?version=1" alt="Album cover" />
                </div>
                <div className="music-player">
                    <iframe 
                        src="https://open.spotify.com/embed/playlist/37i9dQZF1DZ06evO12hDAQ?utm_source=generator" 
                        width="100%" 
                        height="380" 
                        frameBorder="0" 
                        allowTransparency="true" 
                        allow="encrypted-media"
                        title="Embedded Spotify Playlist">
                    </iframe>
                </div>
            </div>
        </div>
    );
}

export default MusicPreview;
